
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
    font-family: 'Helvetica' !important;
}

body{
    font-size: 16px;
    transition: direction 0.5s ease-in-out;
    direction: ltr;
    text-align: left;
}


////////////// general css ////////////////

.btn-clear{
    background: none;
    border: none;
}

.overflow-y-scroll{
    overflow-y: scroll;
    overflow-x: clip;
}

.float-left{
    float: left;
}

.float-right{
    float: right;
}

.text-main{
    color: $mainColor !important;
}

.h-80{
    min-height: 80vh;
}

.text-gray{
    color: gray;
}

*::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.3rem;
    cursor: pointer;
    background-color: #e2ebf5;;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb{
    background-color: $mainColor;
    border-radius: 10px;
}

body::-webkit-scrollbar {
    width: 0;
    background: linear-gradient(to right, white, #17375f14) !important;
}

body::-webkit-scrollbar-thumb{
    background: linear-gradient(to right, white, #17375f14) !important;
}


.hHJTFD{
    margin: 0 !important;
}

.form-select{
    background-image: none !important;
}

// .form-control{
//     // border: 0 !important;
//     // background: transparent !important;
//     // border-radius: 0 !important;
//     // border-bottom: 1px solid #000 !important;
// }

.form-control:focus{
    outline: 0 !important;
    box-shadow: none !important;
}