//Fonts variables
$icomoon-landing-font: 'icxLanding';
$icomoon-landing-path: '../assets/fonts/landing';



///Colors
$white: #fff;
$black: #000;
$lightGray: rgb(248,249,250);
$mainColor: #17375f;

/// land icons
$arrow-down: "\e901";
$logo: "\e900";
$icx-plus: "\ea0a";
$icx-close: "\e902";
  