// // Icons Fonts
// @font-face {
//     font-family: $icomoon-landing-font;
//     src:
//       url('#{$icomoon-landing-path}/#{$icomoon-landing-font}.ttf?9602jh') format('truetype'),
//       url('#{$icomoon-landing-path}/#{$icomoon-landing-font}.woff?9602jh') format('woff'),
//       url('#{$icomoon-landing-path}/#{$icomoon-landing-font}.svg?9602jh##{$icomoon-landing-font}') format('svg');
//     font-weight: normal;
//     font-style: normal;
//     font-display: block;
// }

// .land{
//     font-family: $icomoon-landing-font !important;
//     speak: never;
//     font-style: normal;
//     font-weight: normal;
//     font-variant: normal;
//     text-transform: none;
//     line-height: 1;

//     /* Better Font Rendering =========== */
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

// .land-phone {
//     &::before {
//         content: $landing-phone;
//     }
// }

// .land-android {
//     &::before {
//         content: $land-android;
//     }
// }

// .land-appstore {
//     &::before {
//         content: $land-appstore;
//     }
// }

@font-face {
    font-family: $icomoon-landing-font;
    src:
      url('#{$icomoon-landing-path}/#{$icomoon-landing-font}.ttf?9602jh') format('truetype'),
      url('#{$icomoon-landing-path}/#{$icomoon-landing-font}.woff?9602jh') format('woff'),
      url('#{$icomoon-landing-path}/#{$icomoon-landing-font}.svg?9602jh##{$icomoon-landing-font}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icx{
    font-family: $icomoon-landing-font !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


//// Fonts ///////
@font-face {
    font-family: 'Helvetica';
    src: url(../assets//fonts/Helvetica.ttf) format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Helvetica';
    src: url(../assets//fonts/Helvetica-Bold.ttf) format('truetype');
    font-weight: bold;
}

.icx-arrow-down{
    &::before {
        content: $arrow-down;
    }
}

.icx-logo {
    &::before {
        content: $logo;
    }
}

.icx-plus{
    &::before {
        content: $icx-plus;
    }
}

.icx-close{
    &::before{
        content: $icx-close;
    }
}